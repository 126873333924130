/* eslint-disable react-hooks/exhaustive-deps */

import { createContext, useContext, useEffect, useState } from "react";

const GlobalContext = createContext({});

export const GlobalProvider = (props) => {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState();
  const [amount, setAmount] = useState();
  const [merchantLogo, setMerchantLogo] = useState();
  const [merchantName, setMerchantName] = useState();
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [loadingMain, setLoadingMain] = useState(true);
  const [loading, setLoading] = useState(true);
  const [stripe, setStripe] = useState();
  const [stripeElement, setStripeElement] = useState();
  const [stripeFormFilled, setStripeFormFilled] = useState(false);
  const [payBtnLoading, setPayBtnLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [status, setStatus] = useState(null);
  const [mode, setMode] = useState();
  const urlParam = new URLSearchParams(window.location.search);
  const [xIntentId, setXIntentId] = useState(urlParam.get("xpay_intent_id"));
  const [currency, setCurrency] = useState(urlParam.get("currency") || "USD");
  const [globalIntentData, setGlobalIntentData] = useState({
    paymentMethod: urlParam.get("paymentMethod"),
  });
  const [CSCProps, setCSCProps] = useState();
  const [intentDetails, setIntentDetails] = useState({});

  useEffect(() => {
    setPaymentError(null);
    setGlobalIntentData({
      ...globalIntentData,
      cardName: null,
      cardNumber: null,
      cardValidity: null,
      cardSecurityCode: null,
    });
  }, [step]);

  return (
    <GlobalContext.Provider
      value={{
        step,
        setStep,
        form,
        setForm,
        currency,
        setCurrency,
        currenciesOptions,
        setCurrenciesOptions,
        currenciesOptionsList,
        setCurrenciesOptionsList,
        amount,
        setAmount,
        globalIntentData,
        setGlobalIntentData,
        xIntentId,
        setXIntentId,
        paymentMethodList,
        setPaymentMethodList,
        merchantName,
        setMerchantName,
        intentDetails,
        setIntentDetails,
        loading,
        setLoading,
        loadingMain,
        setLoadingMain,
        stripe,
        setStripe,
        stripeElement,
        setStripeElement,
        stripeFormFilled,
        setStripeFormFilled,
        payBtnLoading,
        setPayBtnLoading,
        paymentError,
        setPaymentError,
        status,
        setStatus,
        mode,
        setMode,
        CSCProps,
        setCSCProps,
        merchantLogo,
        setMerchantLogo,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

import axios from "../axios";
import { useGlobalContext } from "../contexts/GlobalContext";
import { ErrorHandler } from "./../utils/ErrorHandler";

const useTriggerIntent = () => {
  const {
    xIntentId,
    globalIntentData,
    currency,
    setAmount,
    setCurrency,
    setIntentDetails,
    setLoading,
    setMerchantName,
    setStatus,
  } = useGlobalContext();
  const triggerIntent = async () => {
    const urlParam = new URLSearchParams(window.location.search);
    setLoading(true);
    try {
      const { data } = await axios.post(`/payments/v2/trigger-intent`, {
        xIntentId: xIntentId,
        currency: currency || urlParam.get("currency"),
        paymentMethod:
          globalIntentData?.paymentMethod || urlParam.get("paymentMethod"),
      });

      setIntentDetails(data);
      setAmount(data.presentmentAmount);
      setCurrency(data.presentmentCurrency);
      setMerchantName(data.merchantName);

      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  const getIntent = async () => {
    try {
      const { data } = await axios.get(`/payments/get-intent/${xIntentId}`);
      setIntentDetails(data);
      setStatus(data.status);
      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  return { triggerIntent, getIntent };
};

export default useTriggerIntent;

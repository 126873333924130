import { SentryRoutes } from "./v2/utils/initSentry";
import { BrowserRouter, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { GlobalProvider } from "./v2/contexts/GlobalContext";

import GlobalErrorBoundary from "./v2/utils/GlobalErrorBoundary";
import PaymentConfirmation from "./v2/components/PaymentLinks/PaymentConfirmation/index";
import PaymentExpired from "./v2/components/PaymentLinks/PaymentExpired";
import PaymentCompleted from "./v2/components/PaymentLinks/PaymentCompleted";
import Main from "./v2/Main";
import FeatureFlag from "./FeatureFlag";

const App = () => {
  return (
    <GlobalProvider>
        <GlobalErrorBoundary>
          <BrowserRouter>
            <SentryRoutes>
              <Route path="/" element={<FeatureFlag />} />

              {/* V2 path */}
              <Route path="/v2" element={<Main />} />

              {/* Payment Links Routes */}
              <Route path="/confirmation" element={<PaymentConfirmation />} />
              <Route path="/links/completed" element={<PaymentCompleted />} />
              <Route path="/links/expired" element={<PaymentExpired />} />
            </SentryRoutes>
          </BrowserRouter>
          <Toaster />
        </GlobalErrorBoundary>
    </GlobalProvider>
  );
};

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "antd";
import { useState, useEffect } from "react";
import CustomCurrencyField from "./UI/CustomCurrencyField";
import { useGlobalContext } from "./../contexts/GlobalContext";
import { ImSpinner2 } from "react-icons/im";
import useCalculateAmount from "./../hooks/useCalculateAmount";

const TransDetails = () => {
  const { currency, amount, step, merchantName } = useGlobalContext();

  const [amountLoading, setAmountLoading] = useState(true);
  const [radioValue, setRadioValue] = useState(currency);

  const { getCalculatedAmount } = useCalculateAmount();

  useEffect(() => {
    getCalculatedAmount({ setLoading: setAmountLoading });
  }, [radioValue]);

  return (
    <div>
      <Divider />
      {step === 0 && (
        <CustomCurrencyField
          radioValue={radioValue}
          setRadioValue={setRadioValue}
        />
      )}
      <div className="flex items-center justify-between gap-4">
        <div>
          <span className="">Paying to</span>{" "}
          <span className="font-semibold">{merchantName}</span>
        </div>
        <div className="text-right">
          {amountLoading ? (
            <div className="flex items-center justify-center text-sm">
              <ImSpinner2 className="mr-2 animate-spin" />
              <span className="animate-pulse">Calculating...</span>
            </div>
          ) : (
            <div className="font-semibold">
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
                minimumFractionDigits: 2,
              }).format(amount / 100)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransDetails;

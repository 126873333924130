import { useGlobalContext } from "../contexts/GlobalContext";
import axios from "../axios";
import { ErrorHandler } from "../utils/ErrorHandler";

const useCalculateAmount = () => {
  const { xIntentId, setAmount, currency, setStatus } = useGlobalContext();

  const getCalculatedAmount = async ({ setLoading }) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `/payments/v2/calculateAmount/${xIntentId}`,
        { currency: currency }
      );

      setAmount(data.amount);

      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { getCalculatedAmount };
};

export default useCalculateAmount;

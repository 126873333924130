/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Header from "./Header.jsx";
import StepContent from "./StepContent.jsx";
import ProceedButton from "./UI/ProceedButton.jsx";
import TnCBox from "./TnCBox.jsx";
import TransDetails from "./TransDetails";
import { useGlobalContext } from "../contexts/GlobalContext.jsx";
import { Spin } from "antd";
import { ImSpinner2 } from "react-icons/im";
import AntDesignContext from "../contexts/AntDesignContext.jsx";
import PaymentStatusView from "./PaymentStatusView.jsx";
import Breadcrumb from "./UI/Breadcrumb";
import usePaymentMethod from "../hooks/usePaymentMethod.js";
import { stepsData } from "../constants/Steps.js";
import usePrefetchCurrencies from "../hooks/usePrefetchCurrencies.js";

const Container = () => {
  const { loading, status, setStep, intentDetails, setIntentDetails, loadingMain } =
    useGlobalContext();
  const { getPaymentMethod } = usePaymentMethod();
  const urlParam = new URLSearchParams(window.location.search);
  const clientSecret = urlParam.get("payment_intent_client_secret");
  const { getPrefetchCurrencies, getMerchantLogo } = usePrefetchCurrencies();

  const stripeConfirmation = async () => {
    setStep(stepsData.length - 1);
    await getPaymentMethod();
  };

  const prefetch = async () => {
    await getPrefetchCurrencies();  
    await getMerchantLogo();  
  };

  useEffect(() => {
    prefetch();
  }, []);

  useEffect(() => {
    if (clientSecret) {
      setIntentDetails({
        ...intentDetails,
        pg: "STRIPE",
        clientSecret: clientSecret,
        pgPublicKey: urlParam.get("pgPublicKey"),
        xIntentId: urlParam.get("xpay_intent_id"),
        callbackUrl: urlParam.get("callbackUrl"),
      });
      stripeConfirmation();
    }
  }, []);

  const referrer = document.referrer;
  const stimulation =
    referrer === "https://poc.xpaycheckout.com/" || // Prod (Test Mode) Env
    referrer === "http://localhost:5173/"; // Dev Env

  return (
    <AntDesignContext>
      <Spin
        spinning={loadingMain}
        indicator={<ImSpinner2 className="animate-spin" />}
        size="large"
      >
        <Header />
        {status ? (
          <PaymentStatusView />
        ) : (
          <>
            <Breadcrumb />
            <Spin
              spinning={loading}
              indicator={<ImSpinner2 className="animate-spin" />}
              size="large"
            >
              <StepContent />
              <TransDetails />
              <ProceedButton />
              {stimulation && <TnCBox />}
            </Spin>
          </>
        )}
      </Spin>
    </AntDesignContext>
  );
};

export default Container;

import PaymentStatusSuccess from "./StatusScreens/PaymentStatusSuccess";
import PaymentStatusError from "./StatusScreens/PaymentStatusError";
import { useGlobalContext } from "../contexts/GlobalContext";

const PaymentStatusView = () => {
  const { status } = useGlobalContext();

  switch (status) {
    case "SUCCESS":
      return <PaymentStatusSuccess />;
    case "ERROR":
    default:
      return <PaymentStatusError />;
  }
};

export default PaymentStatusView;

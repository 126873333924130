import React from "react";
import { Select, Form } from "antd";
import { getValidationRules } from "../../../utils/validations";
import { useGlobalContext } from "../../../contexts/GlobalContext";

const SelectField = ({ label, name, required, placeholder, options }) => {
  const { globalIntentData, setGlobalIntentData } = useGlobalContext();

  const onChange = (value) => {
    setGlobalIntentData({ ...globalIntentData, [name]: value });
  };

  return (
    <div>
      <Form.Item
        hasFeedback
        label={<div className="text-[#374151]">{label || ""} </div>}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Select
          size="large"
          name={name}
          placeholder={placeholder}
          value={globalIntentData[name]}
          onChange={onChange}
          options={options}
          required={required}
          showSearch
        />
      </Form.Item>
    </div>
  );
};

export default SelectField;

import React from "react";
import InputField from "./UI/FormFields/InputField";
import { fieldNames } from "./../constants/fieldNames";
import cscIcon from "../assets/Icons/card-security-code.png";
import { GoCreditCard } from "react-icons/go";

const ChargeCard = () => {
  return (
    <>
      <div className="flex flex-col">
        <InputField
          name={fieldNames.cardNumber}
          label="Card number"
          required
          placeholder={"1234 1234 1234 1234"}
          suffix={<GoCreditCard size={24} />}
        />
        <div className="grid grid-cols-2 gap-6">
          <InputField
            name={fieldNames.cardValidity}
            label="Expiration date"
            required
            placeholder={"MM/YY"}
          />
          <InputField
            name={fieldNames.cardSecurityCode}
            label="Security code"
            required
            placeholder={"CSC"}
            suffix={<img src={cscIcon} alt="CSC Icon" className="w-6 " />}
          />
        </div>
        <InputField
          name={fieldNames.cardName}
          label="Name on card"
          required
          placeholder={"Cardholder Name"}
        />
      </div>
    </>
  );
};

export default ChargeCard;

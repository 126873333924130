import Loader from "../UI/Loader";
import expiredAnimation from "../../assets/Animations/paymentExpired.json";
import LandingWrapper from "../UI/LandingWrapper";
import GridBackground from "../UI/GridBackground";

const PaymentExpired = () => {
  return (
    <GridBackground>
      <LandingWrapper>
        <div className="pt-20">
          <Loader
            json={expiredAnimation}
            width="300px"
            height="150px"
            loop={false}
            keepLastFrame={true}
            mobileHeight="120px"
            mobileWidth="250px"
          />
          <p className="pt-20 mb-4 text-lg text-center ">
            Your Payment Link is Expired
          </p>
        </div>
      </LandingWrapper>
    </GridBackground>
  );
};

export default PaymentExpired;

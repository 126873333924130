import { useGlobalContext } from "../contexts/GlobalContext";
import axios from "../axios";
import { paymentMethod } from "../constants/paymentMethod";
import { fieldNames } from "./../constants/fieldNames";
import { ErrorHandler } from "./../utils/ErrorHandler";

const usePaymentMethod = () => {
  const {
    xIntentId,
    currency,
    setPaymentMethodList,
    globalIntentData,
    setGlobalIntentData,
    setLoading,
    setStatus,
  } = useGlobalContext();

  const getPaymentMethod = async () => {
    const urlParam = new URLSearchParams(window.location.search);
    setLoading(true);
    try {
      const { data } = await axios.post(`/payments/v2/prefetchPaymentMethods`, {
        intentId: xIntentId,
        currency: currency || urlParam.get("currency"),
      });

      const paymentMethodList = [];

      data.paymentMethods.forEach((item) => {
        paymentMethodList.push(
          paymentMethod.find((method) => method.id === item)
        );
      });
      setPaymentMethodList(paymentMethodList);
      setGlobalIntentData({
        ...globalIntentData,
        [fieldNames.paymentMethod]: data?.preSelected,
      });

      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  const postIngestBillingAddress = async () => {
    setLoading(true);
    try {
      await axios.post(`/payments/ingest-billing-address`, {
        xIntentId: xIntentId,
        billingDetails: {
          email: globalIntentData?.email,
          customerAddress: {
            postalCode: globalIntentData?.pincode,
            country: globalIntentData?.country.split("-")[0],
          },
        },
      });
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { getPaymentMethod, postIngestBillingAddress };
};

export default usePaymentMethod;

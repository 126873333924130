/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from "antd";
import React from "react";
import { useGlobalContext } from "../../contexts/GlobalContext";

const PaymentError = () => {
  const { paymentError } = useGlobalContext();

  return (
    <div>
      <Alert
        message={paymentError || "Something went wrong. Please try again."}
        type="error"
        showIcon
      />
    </div>
  );
};

export default PaymentError;

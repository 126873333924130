import React from "react";
import GridBackground from "./components/UI/GridBackground";
import Container from "./components/Container";
import TestCardsContainer from "./components/TestMode/TestCardsContainer.jsx";
import { stepsData } from "./constants/Steps";
import { useGlobalContext } from "./contexts/GlobalContext";

const Main = () => {
  const { mode, step, globalIntentData } = useGlobalContext();

  return (
    <div>
      <GridBackground>
        <Container />
      </GridBackground>
      {
        mode === "TEST" &&
        step === stepsData.length - 1 &&
        globalIntentData.paymentMethod === "CARD" && <TestCardsContainer />
      }
    </div>
  );
};

export default Main;

import React from "react";
import logo from "../assets/xPayLogoWhite.svg";
import BackButton from "./UI/BackButton";
import { useGlobalContext } from "../contexts/GlobalContext";
import { Tag } from "antd";

const Header = () => {
  const { merchantLogo, setLoadingMain, mode } = useGlobalContext();

  const handleImageLoad = () => {
    setLoadingMain(false);
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-between">
        {merchantLogo ? (
          <>
            <img
              src={merchantLogo}
              alt="xPay logo"
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              className="max-h-10 max-w-20"
            />
            <div className="flex items-center gap-2">
              {mode === "TEST" && <Tag color="gold">Test Mode</Tag>}
              <img src={logo} alt="xPay logo" className="w-20 h-10" />
            </div>
          </>
        ) : (
          <>
            <img src={logo} alt="xPay logo" className="w-20 h-10" />
            {mode === "TEST" && <Tag color="gold">Test Mode</Tag>}
          </>
        )}
      </div>
      <BackButton />
    </div>
  );
};

export default Header;

import axios from "../axios";
import { fieldNames } from "../constants/fieldNames";
import { ErrorHandler } from "../utils/ErrorHandler";
import { Country } from "country-state-city";
import { useGlobalContext } from "../contexts/GlobalContext";

const usePrefetchCurrencies = () => {
  const {
    xIntentId,
    setAmount,
    setCurrency,
    setStatus,
    setCurrenciesOptions,
    setCurrenciesOptionsList,
    globalIntentData,
    setGlobalIntentData,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setMerchantLogo,
  } = useGlobalContext();

  const getPrefetchCurrencies = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        `/payments/v2/prefetchCurrencies/${xIntentId}`
      );

      setAmount(data?.primary?.amount);
      data.secondary && data.secondary.length > 0
        ? setCurrency(data.secondary[0])
        : setCurrency(data?.primary?.currency);

      setMerchantName(data?.merchantName);
      setMode(data?.mode);

      let currenciesOptionsList = [];
      let currenciesOptions = [];

      setGlobalIntentData({
        ...globalIntentData,
        [fieldNames.email]: data?.email,
        [fieldNames.pincode]: data?.pinCode,
        [fieldNames.country]: data?.country
          ? data?.country + "-" + Country.getCountryByCode(data?.country)?.name
          : null,
      });

      if (data.secondary && data.secondary.length > 0) {
        currenciesOptions.push({
          label: getCurrencySymbol(data.secondary[0]),
          value: data.secondary[0],
        });
      }
      currenciesOptions.push({
        label: getCurrencySymbol(data.primary.currency),
        value: data.primary.currency,
      });

      if (data.secondary && data.secondary.length > 0) {
        currenciesOptionsList.push({
          label: getCurrencySymbol(data.secondary[0]),
          value: data.secondary[0],
        });
      }

      currenciesOptionsList.push({
        label: getCurrencySymbol(data.primary.currency),
        value: data.primary.currency,
      });

      data.others.sort();

      data.others.forEach((element) => {
        currenciesOptionsList.push({
          label: getCurrencySymbol(element),
          value: element,
        });
      });

      setCurrenciesOptions(currenciesOptions);
      setCurrenciesOptionsList(currenciesOptionsList);

      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  const getCurrencySymbol = (currency) => {
    let symbol = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
    }).format(0);

    symbol = symbol.replace(/\d/g, "");

    return currency === symbol.slice(0, -1)
      ? currency
      : `${currency} (${symbol})`;
  };

  const getMerchantLogo = async () => {
    try {
      const { data } = await axios.get(`/merchant/getMerchantLogo/${xIntentId}`);
      setMerchantLogo(data?.merchantLogo);

      if(data?.merchantLogo === null)
        setLoadingMain(false);
    } catch (error) {
      ErrorHandler(error, setStatus);
    } 
  };

  return { getPrefetchCurrencies, getMerchantLogo };
};

export default usePrefetchCurrencies;

import { CiCreditCard1 } from "react-icons/ci";
import amazonPay from "../assets/paymentMethods/amazon-pay.png";
import applePay from "../assets/paymentMethods/apple-pay.png";
import CashAppPay from "../assets/paymentMethods/Cash-App.png";

export const paymentMethod = [
  {
    id: "CARD",
    title: "Card",
    description: "Pay securely using your credit or debit card.",
    icon: <CiCreditCard1 />,
    value: "CARD",
  },
  {
    id: "AMAZON_PAY",
    title: "Amazon Pay",
    description: "Use your Amazon account for a fast and secure checkout.",
    icon: <img src={amazonPay} alt="amazonPay" className="w-6 mt-2" />,
    value: "AMAZON_PAY",
  },
  {
    id: "APPLE_PAY",
    title: "Apple Pay",
    description:
      "Make payments effortlessly with Apple Pay on supported devices.",
    icon: <img src={applePay} alt="applePay" className="mt-1 w-9" />,
    value: "APPLE_PAY",
  },
  {
    id: "CASH_APP",
    title: "Cash App",
    description: "Pay using Cash App for a quick and easy transaction.",
    icon: <img src={CashAppPay} alt="cashAppPay" className="w-5" />,
    value: "CASH_APP",
  },
];

import React, { useState } from "react";
import { useGlobalContext } from "../../contexts/GlobalContext";
import TestCard from "./TestCard";
import { authNetTestCards, stripeTestCards } from "../../constants/testCards";

const TestCardsContainer = () => {
  const [isDesktopCollapsed, setDesktopCollapsed] = useState(true);
  const { intentDetails } = useGlobalContext();

  const testCards =
    intentDetails?.pg === "AUTHNET" ? authNetTestCards : stripeTestCards;

  return (
    <div>
      <div className="fixed block bottom-4 right-4">
        {isDesktopCollapsed ? (
          <button
            className="px-4 py-2 text-white bg-blue-500 rounded-full shadow-lg"
            onClick={() => setDesktopCollapsed(false)}
          >
            Show Test Cards
          </button>
        ) : (
          <div className="p-4 bg-white rounded-lg shadow-lg ">
            <div className="flex items-center justify-between gap-10">
              <h3 className="text-lg font-semibold">Test Cards</h3>
              <button
                className="text-sm text-gray-500"
                onClick={() => setDesktopCollapsed(true)}
              >
                Collapse
              </button>
            </div>
            <div className="pt-4 text-green-600">Success Cards:</div>
            <div className="flex flex-col gap-2 pt-2">
              {testCards.success.map((card, index) => (
                <div
                  key={index}
                  className="flex items-start justify-between gap-4 p-4 text-black border rounded-lg shadow-sm drop-shadow-lg"
                >
                  <TestCard card={card} />
                </div>
              ))}
            </div>
            <div className="pt-4 text-red-400">Failure Cards:</div>
            <div className="flex flex-col gap-2 pt-2">
              {testCards.failure.map((card, index) => (
                <div
                  key={index}
                  className="flex items-start justify-between gap-4 p-4 text-black border rounded-lg shadow-sm drop-shadow-lg"
                >
                  <TestCard card={card} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestCardsContainer;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import PaymentStatusSuccess from "./PaymentStatusSuccess";
import PaymentStatusFailed from "./PaymentStatusFailed";
import PaymentStatusRefunded from "./PaymentStatusRefunded";
import PaymentStatusView from "./../../PaymentStatusView";
import useTriggerIntent from "../../../hooks/useTriggerIntent";
import GridBackground from "../../UI/GridBackground";
import LandingWrapper from "../../UI/LandingWrapper";
import { ImSpinner2 } from "react-icons/im";

const PaymentConfirmationScreen = () => {
  const { status } = useGlobalContext();
  const { getIntent } = useTriggerIntent();
  const [loader, setLoader] = useState(true);

  const init = async () => {
    setLoader(true);
    await getIntent();
    setLoader(false);
  };

  useEffect(() => {
    init();
  }, []);

  const renderContent = () => {
    switch (status) {
      case "SUCCESS":
        return <PaymentStatusSuccess />;
      case "REFUNDED":
        return <PaymentStatusRefunded />;
      case "FAILED":
      case "INCOMPLETE":
      case "CREATED":
        return <PaymentStatusFailed />;
      case "LOADING":
      case "ERROR":
      default:
        return <PaymentStatusView />;
    }
  };

  return (
    <GridBackground>
      {loader ? (
        <div className="flex items-center justify-center h-[80vh] text-3xl">
          <ImSpinner2 className="animate-spin" />
        </div>
      ) : (
        <LandingWrapper>{renderContent()}</LandingWrapper>
      )}
    </GridBackground>
  );
};

export default PaymentConfirmationScreen;

import React from "react";
import { useGlobalContext } from "../../contexts/GlobalContext";
import ChargeCard from "../ChargeCard";
import StripeGateway from "../StripeGateway/StripeGateway";
import QuestionBox from "../QuestionBox";
import PaymentError from "../UI/PaymentError";

const Payment = () => {
  const { intentDetails, globalIntentData, paymentError } = useGlobalContext();
  const renderPg = () => {
    switch (intentDetails.pg) {
      case "AUTHNET":
        return <ChargeCard />;
      case "STRIPE":
        return <StripeGateway />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {paymentError && <PaymentError />}
      {globalIntentData.paymentMethod === "CARD" ? <QuestionBox /> : <div className="py-4"></div>}{" "}
      {renderPg()}
    </>
  );
};

export default Payment;

import React from "react";
import { stepsData } from "../constants/Steps";
import { useGlobalContext } from "../contexts/GlobalContext";

const QuestionBox = () => {
  const { step } = useGlobalContext();

  const curStep = stepsData[step];

  return (
    <div className="my-4">
      <div className="flex flex-col gap-2">
        <div className="text-lg font-medium">{curStep.question}</div>
        <div className="text-sm opacity-75">{curStep.description}</div>
      </div>
    </div>
  );
};

export default QuestionBox;
